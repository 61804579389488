@each $_key, $_value in $v-colors {
    .color-#{$_key} {
        @include responsive {
            color: $_value;
        }
    }
}

@each $_key, $_value in $v-colors {
    .bg-#{$_key} {
        @include responsive {
            background-color: $_value;
        }
    }
}
