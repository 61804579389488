
/* -------------------------------------------------------------------------
 * PX TO EM
 *
 * Returns EM equivalent value using a given PX value and a REM reference
 * By default, uses $s-core__font-size on the conversion.
 *
 * s-core-px-to-em(24px, 1.143rem) == 1.5em
 * (based on 14px $s-core__font-size)
 */


// Convert px to em relative to the second argument value

@function s-core-px-to-em($_value, $_reference-value-rem: 1rem, $_root-font-size: $s-core__font-size) {

  @if ($_value == 0) {
    @return 0;
  }

  @each $_given-values in
    $_value
    $_reference-value-rem
    $_root-font-size {

    @if (type-of($_given-values) != number) {
      @error "s-core-px-to-em: `#{$_given-values}` needs to be a number.";
    }

  }

  @if (unit($_value) != "px") {
    @error "s-core-px-to-em: `#{$_value}` needs to be a pixel value.";
  }

  @if (unit($_root-font-size) != "px") {
    @error "s-core-px-to-em: `#{$_root-font-size}` needs to be a pixel value.";
  }

  // Avoid relative units if the project is scoped

  @if ($s-config__scoped) {
    @return $_value;
  }

  @if (unit($_reference-value-rem) != "rem") {
    @error "s-core-px-to-em: `#{$_reference-value-rem}` needs to be a rem value.";
  }

  $reference-value-px: s-core-rem-to-px($_reference-value-rem, false, $_root-font-size);
  @return s-core-strip-units($_value / s-core-strip-units($reference-value-px)) * 1em;
}
