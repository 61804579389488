
/* -------------------------------------------------------------------------
 * BREAKPOINT SUFFIX
 *
 * Returns a breakpoint string with its escaped separator
 * Useful for utilities that uses breakpoints modifiers
 *
 * s-core-breakpoint-suffix(md) == \@md
 */

@function s-core-breakpoint-suffix($_bp-name) {
  @return unquote("#{$s-config__breakpoint--separator}#{$_bp-name}");
}
