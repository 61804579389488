$v-layout__min-resize: 768;
$v-layout-fontsize: 18px;

@function m($_value) {
    @return s-core-px-to-rem($_value, $v-layout-fontsize);
}

@mixin t-fsize($_value) {
    font-size: #{$_value}px;
    $_prop: 280 / 100;
    font-size: #{$_value / $_prop}vw;

    @media (min-width: 520px) {
        $_prop: $v-layout__min-resize / 100;
        font-size: #{$_value / $_prop}vw;
    }
}

@function s-core-px-to-rem($_value, $_root-font-size: $s-core__font-size) {
    @if ($_value == 0) {
        @return 0;
    }

    // Check value content

    @if (type-of($_value) != number) {
        @error "s-core-px-to-rem: `#{$_value}` needs to be a number.";
    }

    @if (unit($_value) == "rem") {
        @return $_value;
    }

    @if (unit($_value) != "px") {
        @error "s-core-px-to-rem: `#{$_value}` needs to be a pixel value.";
    }

    // Check root-font-size content

    @if (type-of($_root-font-size) != number) {
        @error "s-core-px-to-rem: `#{$_root-font-size}` needs to be a number.";
    }

    @if (unit($_root-font-size) != "px") {
        @error "s-core-px-to-rem: `#{$_root-font-size}` needs to be a pixel value.";
    }

    // Avoid relative units if the project is scoped

    @if ($s-config__scoped) {
        @return $_value;
    }

    @return s-core-strip-units($_value) / s-core-strip-units($_root-font-size) * 1rem;
}

@function s-core-strip-units($_value) {
    @if (type-of($_value) != number) {
        @error "s-core-strip-units: `#{$_value}` needs to be a number.";
    }

    @if not unitless($_value) {
        @return $_value / ($_value * 0 + 1);
    }

    @return $_value;
}
