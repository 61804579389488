@each $_key, $_value in map-get($v-texts, "default") {
    .text-#{$_key} {
        @include responsive {
            font-size: $_value;
        }
    }
}

.middle-decoration {
    position: relative;
    span {
        background-color: color("background");
        position: relative;
        z-index: 2;
        padding-left: m(10px);
        padding-right: m(10px);

        .c-navmain & {
            background-color: color("background2");
        }
    }
    &:before {
        content: "";
        width: 160px;
        height: 1px;
        background-color: color(primary);
        left: calc(50% - 80px);
        top: 50%;
        position: absolute;
    }
}

.text-center {
    @include responsive {
      text-align: center;
    }
  }
  
  .font-bold {
    font-weight: bold;
  }
  
  .font-semibold {
    font-weight: 500;
  }