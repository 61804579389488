@charset "UTF-8";

/* carrito */

#cart_lite {
    position: absolute;
    margin: 7px 0 5px 9px;
}

.cart_lite {
    background: url(images/cart_icons/cart_lite.png) no-repeat 0 3px;
}

.cart_lite p {
    padding-bottom: 15px;
}

.cart_lite span.price {
    margin-right: 0;
}

a#expand_button {
    display: block;
    background: url(images/fleche_blue_down.png) no-repeat 93% 45%;
    padding: 4px 0 7px 23px;
    font-size: 12px;
    line-height: 14px;
    text-decoration: none;
    color: #000;
    text-transform: uppercase;
}

/* Carrito expandido */

div.cart_expand {
    position: absolute;
    /*          display: none;*/
    background: #fff url(images/bg_cart_lite.png) repeat-x bottom left;
    width: 215px;
    padding: 15px;
    z-index: 1000;
    outline: hidden;
    top: 25px;
    left: -9px;
    /*	 -moz-border-radius: 0 0 10px 10px;
							-ms-border-radius: 0 0 10px 10px;
							-op-border-radius: 0 0 10px 10px;
					-webkit-border-radius: 0 0 10px 10px;
									border-radius: 0 0 10px 10px;
						 -moz-box-shadow: 0px 0px 10px #999;
							-ms-box-shadow: 0px 0px 10px #999;
							-op-box-shadow: 0px 0px 10px #999;
					-webkit-box-shadow: 0px 0px 10px #999;
									box-shadow: 0px 0px 10px #999;*/
}

.cart_expand h3 {
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    color: #4877b3;
    padding: 0 0 10px 0;
}

.cart_lite_table {
    width: 100%;
}

.cart_lite_table td {
    padding: 5px 10px 5px 0;
    border-bottom: 1px solid #a9c8f4;
}

.cart_lite_table td.product_name {
    font-size: 11px;
}

.cart_lite_table td.product_name a {
    text-transform: lowercase;
    color: #000;
    font-weight: bold;
}

.cart_lite_table td.price {
    background-color: #4877b0;
    color: #fff;
    font-weight: bold;
    padding: 5px 10px;
}

.cart_lite_table td.total_price {
    border-bottom: none;
    font-weight: bold;
}

.btn {
    border: none;
    background-repeat: repeat-x;
    padding: 5px 20px;
    font-size: 14px;
    font-weight: 700;
    line-height: 30px;
    display: block;
    border-radius: 5px;
    text-transform: uppercase;
}

.btn.btn_big {
    padding: 0 25px;
    line-height: 42px;
    font-size: 16px;
    font-weight: 400;
}

input.btn {
    border: 0;
    font-size: 11px;
    height: 30px;
    line-height: 25px;
    cursor: hand;
    cursor: pointer;
}
input.btn.btn_big {
    border: 0;
    font-size: 15px;
    height: 42px;
    line-height: 38px;
}

.btn_white {
    color: #000;
}
.btn_white:hover {
}
.btn_white.btn_big:hover {
}

.btn_red {
    background-color: color('primary');
    color: #fff;
}
.btn_grey {
    color: #fff;
    background-color: color('grey');
}
.btn_secondary {
    color: color('black');
    background-color: color('secondary');
}
.btn_red.btn_big {
}
.btn_red:hover {
    color: #fff;
}
.btn_red.btn_big:hover {
}

/* Las direcciones */

div.address,
div.address_select {
    float: left;
    width: 320px;
    margin-right: 20px;
}
div.address_field {
    clear: both;
    width: 320px;
    /*  min-height: 20px;*/
    padding: 2px 0 2px 0;
    margin: 5px 0;
}
div.address_field span {
    margin: 0 10px 5px 0;
    width: 100px;
    float: left;
}
div.address_field span.value {
    font-weight: bold;
}
div.shop_buttons {
    margin: 0px 0;
    width: 100%;
    clear: both;
}
div.shop_buttons a {
    background-image: url(images/cart_icons/shop_icons.gif);
    background-repeat: no-repeat;
    padding: 4px 0 0 22px;
    float: left;
    height: 18px;
    display: block;
    margin-right: 20px;
    color: #333;
    font-weight: bold;
}

/**/
div.visor {
    padding: 10px 0;
    position: relative;
}
div.subvisor {
    padding: 10px 0;
}
div.shop_buttons a.add_address {
    background-position: left 0;
}
div.shop_buttons a.edit_address {
    background-position: left -22px;
}
div.shop_buttons a#create_pass_link {
    background-position: left -42px;
    width: 100%;
    clear: both;
    display: block;
    margin-bottom: 10px;
}

/* Los pasos de compra */
div.checkout_tabs {
    margin-top: 15px;
    /*padding-bottom: 25px;*/
}

div.checkout_tabs ul {
    overflow: hidden;
    /*border-bottom: 1px solid #A7C0DB;*/
}

div.checkout_tabs li {
    font-weight: bold;
    font-size: 13px;
    line-height: 20px;
    float: left;
    list-style: none !important;
    margin: 0 5px 0 0;
    padding: 5px 25px 5px 15px;
    /*color: #000;*/
    text-transform: uppercase;
    background: url(images/cart_icons/checkout_tab_bg.png) no-repeat right center;
}
div.checkout_tabs li,
div.checkout_tabs li.inactive a {
    color: #999;
    text-decoration: none;
}
div.checkout_tabs li.active a {
    color: #000;
    text-decoration: none;
}
/*div.checkout_tabs li.current.current{
			background: url(images/cart_icons/checkout_tab_current.png) repeat-x 0 0;
		}
		
		div.checkout_tabs li.active{
			background: url(images/cart_icons/checkout_tab_active.png) repeat-x 0 0;
		}*/

div.checkout_tabs li.current a,
div.checkout_tabs li.active a {
    color: #000;
}

/* Cupones */

#coupon {
    margin: 10px 0;
}

#promo_title {
    color: #4877b0;
    font-weight: bold;
    font-size: 18px;
    line-height: 20px;
    padding-bottom: 10px;
}

#promo_info .input {
    float: left;
    margin-right: 10px;
}

/** Factura */
div.shop_invoice_items table,
div.shop_invoice_addresses table {
    width: 100%;
}
div.shop_invoice p {
    margin: 0 !important;
    font-size: 11px !important;
}
div.shop_invoice tr {
    vertical-align: top;
}
div.shop_invoice_addresses td {
    padding: 10px;
}
p.store_name,
p.customer_company {
    font-weight: bold;
}
.shop_invoice_store_data {
    padding-left: 400px;
}
.shop_invoice_date_data,
.shop_invoice_method_label {
    font-weight: bold;
}
.shop_invoice_info {
    padding: 5px;
    margin-bottom: 5px;
    background: #eee;
}
div.shop_invoice p.shop_invoice_header {
    font-size: 14px !important;
    font-weight: bold;
    color: #999;
}
div.shop_order_messages,
div.shop_order_statuses {
    margin: 15px 0;
}

div.shop_order_messages .textarea {
    padding-top: 10px;
}

div.shop_order_messages .textarea textarea {
    width: 95%;
}

div.shop_order_messages h3,
div.shop_order_statuses h3 {
    font-weight: bold;
    font-size: 14px;
    color: #999;
    margin-bottom: 10px;
}
div.shop_order_message {
    border-bottom: 1px solid #ccc;
    margin: 10px 0;
}
p.shop_order_messages_header {
    font-weight: bold;
    margin: 0 !important;
}

/** Carro */


table.cart {
    margin-top: 15px;
    font-size: 15px;
    line-height: 12px;
}

div.cart_expand table.cart {
    width: 100%;
}

table.cart th {
    border-bottom: 1px solid #ccc;
    padding: 8px 8px 5px 8px;
    text-align: center;
    font-weight: bold;
    color: #fff;
    background-color: #000;
}
table.cart td {
    border-bottom: 1px solid #ccc;
    padding: 10px 8px;
    vertical-align: middle;
    text-align: center;
}
/** Listados órdenes de pedido */
td.shop_pdf_download a {
    display: block;
    height: 38px;
    width: 38px;
    overflow: hidden;
    text-indent: -1000px;
    background: url(images/cart_icons/pdf.png) no-repeat 0 0;
    float: right;
}

table.cart td.shop_invoice_address_invoice,
table.cart td.shop_invoice_address_delivery,
table.cart td.shop_invoice_store_data,
table.cart td.title {
    text-align: left;
}

table.cart td.price_unit,
table.cart td.price,
table.cart td.tax {
    text-align: right;
}
/*
	div.cart_lite table.cart td {
		padding: 2px 1px;
		font-size: 10px;
		vertical-align: top;
	}*/

table.cart td.buttons {
    padding: 0;
}

table.cart td.subtotal,
table.cart td.total,
table.cart td.taxes,
table.cart td.shipping {
    text-align: right;
}

table.cart td.subtotal,
table.cart td.total {
    text-align: right;
    font-weight: bold;
}

table.cart td.total {
    background: url(images/bg_input_loginbox.jpg) repeat-x 0 0;
    color: #000;
    border-bottom: 0;
}

table.cart td.subtotal {
    color: #666;
}

table.cart td.taxes {
    color: #333;
}

span.cart_add a,
span.cart_disc a,
span.cart_delete a {
    display: block;
    width: 9px;
    height: 9px;
    text-indent: -1000px;
    overflow: hidden;
    background-image: url(images/cart_icons/cart_buttons.gif);
    background-repeat: no-repeat;
    margin: 5px 0;
}
span.cart_add a {
    background-position: 0 0;
}
span.cart_disc a {
    background-position: 0 -14px;
}
span.cart_delete a {
    background-position: 0 -26px;
    text-indent: 0;
    padding-left: 14px;
    width: auto;
    height: auto;
}

table.cart_header td {
    width: 300px;
    padding: 5px 0;
}
table.cart_header br {
    margin: 0;
}
/* Botones del checkout */

#checkout_left {
    width: 60%;
    float: left;
    margin-top: 8px;
}

#checkout_left .submit {
    margin-right: 5px;
}

#checkout_right {
    width: 30%;
    float: right;
}

/* Anular pedido */

div.cancel_cart {
    /*float: left;
		width: 45%;*/
}

div.cancel_cart a {
    padding: 7px 20px;
    text-align: center;
    text-transform: uppercase;
    display: block;
    margin: 3px 0;
    background: #c6d6e8 url(images/form/bg_submit_on.png) repeat-x 0 0;
    color: #061939;
    font-weight: bold;
    float: left;
}

div.cancel_cart a:hover {
    background: #071a3a url(images/form/bg_submit.png) repeat-x 0 0;
    color: #fff;
}

/* Continuar */
div.cart {
    /*float: right;
		width: 45%;*/
    text-align: right;
}

div.cart a {
    padding: 7px 20px;
    text-align: center;
    text-transform: uppercase;
    display: block;
    margin: 3px 0;
    background: #071a3a url(images/form/bg_submit.png) repeat-x 0 0;
    color: #fff;
    font-weight: bold;
    float: right;
}

div.cart a:hover {
    background: #c6d6e8 url(images/form/bg_submit_on.png) repeat-x 0 0;
    color: #061939;
}

div.cart.submit_left,
div.cart.submit_left a {
    float: left;
}

/*span.category_title {
		font-size: 10px;
		text-transform: uppercase;
	}
	div.cart_lite span.category_title {
		font-size: 8px;
		text-transform: none;
	}
	span.product_title {
		font-weight: bold;
	}
	span.attribute_title {
		font-size: 11px;
	}
	div.cart_lite span.attribute_title {
		font-size: 9px;
	}*/

/** Checkout */
div.checkout h3 {
    padding-bottom: 10px;
    margin: 15px 0 0 0;
}

/** SWF Upload */
.progressWrapper,
div.asset_container {
    width: 70%;
}
.progressWrapper {
    overflow: hidden;
}

.progressContainer {
    margin: 5px;
    padding: 4px;
    border: solid 1px #e8e8e8;
    background-color: #f7f7f7;
    overflow: hidden;
}
/* Message */
#message {
    color: #d2100b;
    font-weight: bold;
}

/* Error */
div.red {
    border: solid 1px #b50000;
    background-color: #ffebeb;
}

/* Current */
.green {
    border: solid 1px #ddf0dd;
    background-color: #ebffeb;
}

/* Complete */
.blue {
    border: solid 1px #cee2f2;
    background-color: #f0f5ff;
}

.progressName {
    font-size: 8pt;
    font-weight: 700;
    color: #555;
    width: 323px;
    height: 14px;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
}

.progressBarInProgress,
.progressBarComplete,
.progressBarError {
    font-size: 0;
    width: 0%;
    height: 2px;
    background-color: blue;
    margin-top: 2px;
}

.progressBarComplete {
    width: 100%;
    background-color: green;
    visibility: hidden;
}

.progressBarError {
    width: 100%;
    background-color: red;
    visibility: hidden;
}

.progressBarStatus {
    margin-top: 2px;
    width: 337px;
    font-size: 7pt;
    text-align: left;
    white-space: nowrap;
}

a.progressCancel {
    font-size: 0;
    display: block;
    height: 14px;
    width: 14px;
    background-image: url(images/cart_icons/swf_cancelbutton.gif);
    background-repeat: no-repeat;
    background-position: -14px 0px;
    float: right;
}

a.progressCancel:hover {
    background-position: 0px 0px;
}
div.swfupload_button {
    margin: 15px 0;
}

div.shop_indicator {
    position: fixed;
    left: 55%;
    top: 35%;
    width: 100px;
    height: 100px;
    background: #fff url(images/cart_icons/ajaxloader.gif) no-repeat 50% 50%;
    /*border: 3px solid #999;
		 -moz-border-radius: 1em;
			-ms-border-radius: 1em;
	-webkit-border-radius: 1em;
			-op-border-radius: 1em;
					border-radius: 1em;	*/
}

/* -- SWFUpload Object Styles ------------------------------- */
.swfupload {
    vertical-align: top;
}
div.nota {
    margin-top: 40px;
}
div.nota p {
    font-size: 10px;
    color: #999;
}
div.nota p strong {
    color: #000;
}

div.payment_loading {
    text-align: center;
    padding: 100px 0;
    font-size: 14px;
}

/**********************************************************************/



.c-cart {
    background-color: #f7f7f7;
    position: fixed;
    height: calc(100vh - 40px);
    top: 50px;
    right: 0;
    width: 100%;
    max-width: 450px;
    padding: 20px;
    z-index: 14;
    transform: translateX(550px);
    transition: transform .5s;
    &.active {
        transform: translateX(0);
    }

    h3 {
        margin-top: 20px;
        font-size: 24px;
    }

    @include respond-to('xs'){
      top: 80px;
    }

    .c-cart-close {
        font-size: 30px;
        position: absolute;
        top: 20px;
        right: 20px;
    }
}
