
/* -------------------------------------------------------------------------
 * LINE HEIGHTS
 *
 * Values should be expressed unit less
 */


// Main line heights

$v-line-height--tiny:            .9 !default;
$v-line-height--small:           1.25 !default;
$v-line-height--medium:          1.412 !default;
$v-line-height--large:           2 !default;

// LINE HEIGHTS MAP

$v-line-heights: (
  "tiny":       $v-line-height--tiny,
  "small":      $v-line-height--small,
  "medium":     $v-line-height--medium,
  "large":      $v-line-height--large,
) !default;


@function v-line-height( $_type){
  @return map-get( $v-line-heights, $_type);
}