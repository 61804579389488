
/* -------------------------------------------------------------------------
 * PX TO REM
 *
 * Returns REM equivalent value using a given PX value.
 * By default, uses $s-core__font-size on the conversion.
 *
 * s-core-px-to-rem(16px) == 1.143rem
 * (based on 14px $s-core__font-size)
 */


@function s-core-px-to-rem($_value, $_root-font-size: $s-core__font-size) {

  @if ($_value == 0) {
    @return 0;
  }

  // Check value content

  @if (type-of($_value) != number) {
    @error "s-core-px-to-rem: `#{$_value}` needs to be a number.";
  }

  @if (unit($_value) == "rem") {
    @return $_value;
  }

  @if (unit($_value) != "px") {
    @error "s-core-px-to-rem: `#{$_value}` needs to be a pixel value.";
  }

  // Check root-font-size content

  @if (type-of($_root-font-size) != number) {
    @error "s-core-px-to-rem: `#{$_root-font-size}` needs to be a number.";
  }

  @if (unit($_root-font-size) != "px") {
    @error "s-core-px-to-rem: `#{$_root-font-size}` needs to be a pixel value.";
  }

  // Avoid relative units if the project is scoped

  @if ($s-config__scoped) {
    @return $_value;
  }

  @return s-core-strip-units($_value) / s-core-strip-units($_root-font-size) * 1rem;

}
