
/* -------------------------------------------------------------------------
 * RATIO SUFFIX
 *
 * Returns a ratio string with its escaped separator
 * Useful for objects and utilities that uses ratio modifiers
 *
 * s-core-ratio-suffix(16, 9) == 16\:9
 */

@function s-core-ratio-suffix($_width, $_height) {
  @return unquote("#{$_width}#{$s-config__ratio--separator}#{$_height}");
}
