@mixin respond-to($_breakpoint) {
  @if map-has-key($v-layouts, $_breakpoint) {
    @media (max-width: map-get($v-layouts, $_breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin min-to($_breakpoint) {
  @if map-has-key($v-layouts, $_breakpoint) {
    @media (min-width: map-get($v-layouts, $_breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin responsive() {
  @content;
  @each $_key, $_size in $v-layouts {
    @include min-to($_key) {
      body &\:#{$_key} {
        @content;
      }
    }
  }
}

@mixin container() {
  margin-right: auto;
  margin-left: auto;
  padding-right: gutter-margin(map-get($gutter, default));
  padding-left: gutter-margin(map-get($gutter, default));
  max-width: $v-layout-max-width;
}

@mixin overpass() {
  margin-left: -3000px;
  margin-right: -3000px;
  padding-left: 3000px;
  padding-right: 3000px;
}

@mixin overpass-left() {
  margin-left: -3000px;
  padding-left: 3000px;
}

@mixin overpass-right() {
  margin-right: -3000px;
  padding-right: 3000px;
}

@mixin fullwidth($is-row: false) {
  $_division: 9;
  $_division_sm: 1.5;
  $_division_xs: 2;

  @if $is-row {
    $_division: 1.68;
    $_division_sm: 0.73;
    $_division_xs: 1;
  }

  margin-left: #{map-get($gutter, default) * -1};
  margin-right: #{map-get($gutter, default) * -1};

  @media (min-width: #{$v-layout-max-width}) {
    margin-left: calc(((100vw - #{$v-layout-max-width}) / 2 * -1) - #{map-get($gutter, default) / $_division});
    margin-right: calc(((100vw - #{$v-layout-max-width}) / 2 * -1) - #{map-get($gutter, default) / $_division});
  }

  @each $breakpoint in $l-grid-breakpoints-reverse {
    $name: nth($breakpoint, 1);
    $size: nth($breakpoint, 2);
    $container: nth($breakpoint, 3);

    @include respond-to(sm) {
      @if $container != auto {
        margin-left: calc(((100vw - #{$container}) / 2 * -1) - #{map-get($gutter, $name) / $_division_sm});
        margin-right: calc(((100vw - #{$container}) / 2 * -1) - #{map-get($gutter, $name) / $_division_sm});
      } @else {
        margin-left: #{map-get($gutter, $name) * -1 / $_division_sm};
        margin-right: #{map-get($gutter, $name) * -1 / $_division_sm};
      }
    }

    @include respond-to(xs) {
      @if $container != auto {
        margin-left: calc(((100vw - #{$container}) / 2 * -1) - #{map-get($gutter, $name) / $_division_xs});
        margin-right: calc(((100vw - #{$container}) / 2 * -1) - #{map-get($gutter, $name) / $_division_xs});
      } @else {
        margin-left: #{map-get($gutter, $name) * -1 / $_division_xs};
        margin-right: #{map-get($gutter, $name) * -1 / $_division_xs};
      }
    }
  }
}
