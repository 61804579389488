
@each $_size, $_width in $v-layouts {

  .hide-#{$_size} {
    @include respond-to($_size){
      display: none !important;
    }
  }
  .show-#{$_size} {
    display: none;
    @include respond-to($_size){
      display: block;
    }
  }
}

.hidden {
  display: none !important;
  @include responsive {
    display: none !important;
  }
}

.block {
  display: block !important;
  @include responsive {
    display: block !important;
  }
}

.flex {
  display: flex !important;
  @include responsive {
    display: flex !important;
  }
}

.inline-flex {
  display: inline-flex !important;
  @include responsive {
    display: inline-flex !important;
  }
}

.justify-center {
  justify-content: center;
}
