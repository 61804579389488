
/* -------------------------------------------------------------------------
 * CONFIG
 *
 * Global configuration
 */


/* SCOPED
 * enable if you are scoping the output in a selector
 */

$s-config__scoped:                    false !default;


/* ENVIRONMENT
 * dev .................. Development
 * prod ................. Production
 */

$s-config__env:                       "dev" !default;


/* DEBUG
 * for debugging proposes
 */

$s-config__debug:                     if($s-config__env == "dev", true, false) !default;

$s-core__font-size:          16px !default;