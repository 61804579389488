.c-footer {
    background-image: url("/themed/celayeta/img/bg/footer.jpg");
    background-size: cover;
    background-position: center center;
    padding: 50px 40px;
    @include respond-to("xs") {
        padding: 30px 40px;
    }
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__logo {
        margin-bottom: 30px;

        img {
            width: 100px;
        }
    }

    &__nav {
        ul {
            display: flex;
            justify-content: center;
            list-style: none;
            li {
                color: #fff;
                a {
                    color: #fff;
                    text-transform: uppercase;
                    font-weight: bold;
                }

                &:after {
                    content: "|";
                    margin: 0px 20px;
                }

                &:last-child:after {
                    display: none;
                }
            }
        }
    }

    &__share {
        margin-top: 20px;
        display: flex;
        > div {
            margin: 0px 10px;
        }
        a {
            display: inline-block;
            background-color: rgba(#000, 0.6);
            font-size: 24px;
            width: 40px;
            height: 40px;
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #fff;
        }
    }
}

.c-footer-bottom {
    background-color: #000;
    display: flex;
    justify-content: center;

    @include respond-to("xs") {
        flex-direction: column;
        align-items: center;
    }

    a,
    & {
        color: #fff;
        padding: 15px 10px;
    }

    span {
        margin: 0px 10px;

        i {
            margin-right: 5px;
        }
    }
}
