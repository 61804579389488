@charset "UTF-8";

	#userbox{
		float: right;
	}
		
		#usernav{
			float: right;
    }
		
			#usernav li{
				float: left;
				padding: 0 5px;
				font-size: 13px;
				text-align: center;
			}
			
			#usernav li.first,
			#usernav li#navFB{border-left: none;}
			
			#usernav li#navFB{margin-top: 0; padding-left:0;}
			
				#usernav li a{
          color: #000;
				}
				
				#usernav li#user_name {max-width: 290px;}
				
					#usernav li#user_name span{
						text-transform: none;
						color: #000;
						font-weight:bold;
					}	