

@mixin font($_type) {
  font-family: font($_type);
}

@mixin lead($_type, $_xs: false) {
  line-height: lead($_type);
  @if $_xs {
    @include respond-to(xs){
      line-height: lead($_xs);
    }
  }
}

@mixin fz($_size, $_xs: false, $_sm: false) {
  font-size: $_size;
  @if $_xs {
    @include respond-to(xs){
      font-size: $_xs;
    }
  }

  @if $_sm {
    @include respond-to(sm){
      font-size: $_sm;
    }
  }
}

@mixin text($_type, $_xs: false, $_sm: false) {
  font-size: text($_type);
  @if $_sm {
    @include respond-to(sm){
      font-size: text($_sm);
    }
  }

  @if $_xs {
    @include respond-to(xs){
      font-size: text($_xs);
    }
  }
}

@mixin weight($_type, $_xs: false) {
  font-weight: weight($_type);
  @if $_xs {
    @include respond-to(xs){
      font-weight: weight($_xs);
    }
  }
}


@mixin semibold() {
  font-weight: weight(semibold);
  font-family: font(secondary);
}