
$_props: (
  'margin-top': 'mt',
  'margin-bottom': 'mb',
  'margin-left': 'ml',
  'margin-right': 'mr',
  'padding-top': 'pt',
  'padding-bottom': 'pb',
  'padding-left': 'pl',
  'padding-right': 'pr'
);

@each $_prop, $_class in $_props {
  @each $_key, $_value in $v-spaces {
    .#{$_class}-#{$_key} {
      @include responsive {
        #{$_prop}: $_value;
      }
    }
  }
}

