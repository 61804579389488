.o-text {
  &--header {
    @include text(4xl, 3xl);
    @include weight(bold);
    @include lead(1);
  }

  &--title {
    @include text(3xl-2, 2xl, 3xl);
    @include weight(bold);
    line-height: 1;
  }

  &--title-item {
    @include text(2xl-2, xl-2);
    @include weight(bold);
    @include lead(1);
  }
  
  &--title-item-2 {
    @include text(2xl, 2xl);
    @include weight(bold);
    @include lead(1);
  }
  
  &--title-line {
    text-transform: uppercase;
    &:before {
      content: "";
      display: inline-block;
      width: 50px;
      margin-right: 5px;
      border-bottom: 1px solid color(secondary);
    }
  }

  &--subtitle {
    @include text(2xl, xl, xl);
    @include weight(bold);
    @include font(secondary);
    line-height: 1.4;
  }

  &--subtitle-2 {
    @include text(2xl, xl);
    @include weight(bold);
    @include lead(3);
  }

  &--summary {
    @include weight(bold);
    @include font(secondary);
    @include lead(4);
  }

  &--summary-2 {
    @include text(xl, normal);
    @include font(secondary);
    @include lead(3, '3-2');
    @include weight(normal, bold);

  }

  &--entresaca {
    @include weight(bold);
    @include font(secondary);
    @include lead(3);
  }
}