.c-products {
    @include flex-columns(3, 50px);

    @include respond-to("xs") {
        @include flex-columns(2, 5px);
    }
    > div {
        margin-bottom: 50px;

        @include respond-to("xs") {
            margin-bottom: 10px;
        }

        > div {
            height: 100%;
        }
    }
}

.c-product {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: color("background");
    border: 4px solid #fff;
    padding: 15px;

    @include respond-to("xs") {
        padding: 15px 10px;
    }

    &__content {
    }

    &__image {
        overflow: hidden;
        img {
            width: 100%;
            height: 250px;
            @include respond-to("xs") {
                height: 150px;
            }
            
            transform: translateY(-20px);
            position: relative;
            top: 20px;
            object-fit: cover;

            &:hover {
                opacity: .7;
            }
        }
    }

    &__title {
        flex-grow: 1;
        padding-top: 20px;
        margin-bottom: 10px;
        
        h2 {
            line-height: 1;
            margin-bottom: 5px;
            padding: 0;
            a {
                color: color('black');

                &:hover {
                    color: color('primary');
                }
            }
            @include respond-to("xs") {
                font-size: 18px;
            }
        }
        h3 {
            color: color("grey");
        }
    }

    &__price {
        font-size: 20px;
        font-family: font('secondary');
    }

    &__buy {
        border-top: 1px solid color("border");
        padding-top: 15px;
        form {
            display: flex;

            @include respond-to("xs") {
                flex-direction: column;
            }
        }
    }

    &__button {
        width: 100%;
        @include min-to("xs") {
            margin-left: 20px;
        }

        @include respond-to("xs") {
            margin-top: 10px;
        }

        .c-product-view & {
            margin-left: 20px;

            @include respond-to("xs") {
                margin-top: 0px;
            }
        }

        button {
            cursor: pointer;
            width: 100%;
            height: 100%;
            min-height: 40px;

            &:hover {
                opacity: 0.6;
            }
        }
    }
}

.c-quantity {
    background: #fff;
    display: flex;
    align-items: center;
    font-weight: 100;

    button {
        background: none;
        border: none;
        font-size: 20px;
    }

    border: 1px solid color("black");
    border-radius: 10px;

    input {
        background: none;
        border: none;
        text-align: center;
        width: 50px;
        font-size: 18px;

        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
            -webkit-appearance: none;
        }
        margin: 0;
        &:focus {
            box-shadow: none !important;
        }
    }
}

.c-product-view {
    &__body {
        padding: 10px 0px;
        @include overpass();
        background-color: #fff;
    }
    &__features {
        padding-top: 50px;
        padding-bottom: 30px;
        @include overpass();
        background-color: #fff;

        ._columns {
            @include min-to("xs") {
                columns: 2;
                column-gap: 100px;
            }
            padding-bottom: 30px;
            border-bottom: 1px solid color("black");
        }

        h3 {
            font-size: 26px;
            text-transform: uppercase;
            margin-bottom: 30px;
            border-bottom: 1px solid color("black");
        }
    }

    .c-feature {
        break-inside: avoid;

        ._title {
            font-weight: bold;
        }
        ._text {
            margin-bottom: 1.5em;
            color: color("grey");
        }
    }
}

.c-product-header {
    background-color: #fff;
    @include min-to("xs") {
        display: flex;
        padding: 20px;
    }
    margin-bottom: 20px;

    &__image {
        img {
            @include respond-to("xs") {
                height: 300px;
                width: 100%;
                object-fit: cover;
            }
        }
    }
    &__content {
    }
    &__title {
        padding-top: 30px;
        @include min-to('xs'){
            padding-top: 100px;
        }

        ._title {
            text-transform: uppercase;
            margin-bottom: 0;
        }
        ._subtitle {
            padding-top: 10px;
            color: color("grey");
            font-size: 20px;
        }
    }

    &__price,
    &__buy {
        max-width: 300px;
    }

    &__price {
        border-top: 1px solid color("border");
        padding-top: 10px;
        font-size: 24px;
    }

    &__buy {
        margin-top: 20px;
        form {
            display: flex;
        }
    }

    &__summary {
        margin-top: 20px;
        font-weight: bold;
        color: color("grey");
        max-width: 500px;
    }
}
