.d-hide {
  @include responsive {
    display: none !important;
  }
}

.d-flex {
  @include responsive {
    display: flex !important;
  }
}

.d-block {
  @include responsive {
    display: block !important;
  }
}