
/* -------------------------------------------------------------------------
 * REM TO PX
 *
 * Returns PX equivalent value using a given REM value.
 * By default, uses $s-core__font-size on the conversion, and the returned value is rounded.
 *
 * s-core-rem-to-px(1.143rem) == 16px
 * (based on 14px $s-core__font-size)
 */


@function s-core-rem-to-px($_value, $_rounded: true, $_root-font-size: $s-core__font-size) {

  @if ($_value == 0) {
    @return 0;
  }

  @each $_given-values in
    $_value
    $_root-font-size {

    @if (type-of($_given-values) != number) {
      @error "s-core-rem-to-px: `#{$_given-values}` needs to be a number.";
    }

  }

  @if (unit($_value) == "px") {
    @return $_value;
  }

  @if (unit($_value) != "rem") {
    @error "s-core-rem-to-px: `#{$_value}` needs to be a rem value.";
  }

  @if (unit($_root-font-size) != "px") {
    @error "s-core-rem-to-px: `#{$_root-font-size}` needs to be a pixel value.";
  }

  $_value-px: ($_root-font-size * s-core-strip-units($_value)) * 1px;

  @if ($_rounded != true) {
    @return $_value-px;
  }

  @return round($_value-px);

}
