@charset "UTF-8";
@import url(http://fonts.googleapis.com/css?family=Quattrocento+Sans:400,400italic,700,700italic&subset=latin,latin-ext);

$g-box-sizing:          border-box !default;


// Apply box sizing according SETTINGS > CONFIG > SCOPED
  // Otherwise, apply box sizing from root html tag
  html {
    box-sizing: $g-box-sizing;
  }

  * {
    &,
    &::before,
    &::after {
      box-sizing: inherit;
    }
  }r


/* CSS Document */

/** ---- RESET ----- */

body,
div,
span,
h1,
h2,
h3,
h4,
h5,
p,
ul,
ol,
li,
img,
table,
tr,
td,
form,
input,
legend,
fieldset,
object,
embed,
param,
a {
  margin: 0;
  padding: 0;
  outline: none;
}

html {
  overflow-x: hidden;
}

body {
  background: url("/theme/celayeta/img/bg/cepas.jpg");
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  padding-top: 0 !important;

  @include respond-to('xs'){
    background-image: none !important;
  }
  margin: 0;
  padding: 0;
}

img,
fieldset {
  border: 0;
}

#navmain ul,
#navmain ul li,
#useraccess ul,
#useraccess li,
#usernav ul,
#usernav li,
#navbottom ul,
#navbottom li {
  list-style-type: none;
}

.limpia {
  float: none;
  display: block;
  clear: both;
  overflow: hidden;
}

.hide {
  display: none;
}
.ajax-loader {
  background: url(images/ajax_loader.gif) no-repeat scroll 0 0 transparent;
  display: block;
  height: 15px;
  overflow: hidden;
  position: absolute;
  right: -19px;
  text-indent: -1000px;
  top: 0;
  width: 15px;
}

hr {
  display: block;
  height: 1px;
  width: 100%;
  clear: both;
  border: none;
}

hr.visible {
  border-top: 1px solid #ccc;
}

h2 {
  font: 400 23px/26px font('secondary');
  padding: 20px 0 10px;
}
h3 {
  font: 400 14px/16px font('primary');
  padding-bottom: 5px;
}
h4 {
  font: 700 12px/14px font('primary');
  padding-bottom: 5px;
}

a {
  color: #666;
  text-decoration: none;
}
a:hover {
  color: #000;
}

a.black {
  font-weight: bold;
  color: #000;
}
a.black:hover {
  color: #666;
}

.floatRight {
  float: right;
}
.floatLeft {
  float: left;
}

img.floatRight {
  margin: 0 0 25px 25px;
}
img.floatLeft {
  margin: 0 25px 25px 0;
}

.text_small {
  font-size: 11px;
}
.text_big {
  font-size: 18px;
}

.textoDerecha,
.text_right {
  text-align: right;
}
.textoIzquierda,
.text_left {
  text-align: left;
}
.textoCentrado,
.text_center {
  text-align: center;
}

.textoRojo {
  color: #cc0000;
}
.textoVerde {
  color: #79b438;
}
.textoGris {
  color: #666;
}
.textoAzul {
  color: #4977b0;
}

.upper {
  text-transform: uppercase;
}
.no_screen {
  display: none;
}

.btn_print {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0 0 0 40px;
  line-height: 32px;
  color: #000;
  font-weight: bold;
  background: url(images/cart_icons/print.png) no-repeat 0 0;
}

.message_wrap,
.message_wrap_ok {
  background: #ffbaba;
  border: 1px solid #d8000c;
  color: #000;
  padding: 5px 10px;
  position: absolute;
  text-align: center;
  z-index: 9998;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -webkit-border-radius: 6px;
  -op-border-radius: 6px;
  border-radius: 6px;
}
/*#login_message {
  position: absolute;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  top: 100px;
  left: 40%;
  background: #E8F6E8;
  width: 245px;
  padding: 30px 50px;
  color: #000;
  z-index: 9999;
  border: 1px solid #009900;
  	 -moz-border-radius: 10px;
			-ms-border-radius: 10px;
	-webkit-border-radius: 10px;
			-op-border-radius: 10px;
					border-radius: 10px;
}*/

.product_content .message_wrap {
  position: inherit;
  clear: both;
  overflow: hidden;
  width: auto;
}

.message_wrap a {
  color: #d8000c;
}

.message_wrap_ok {
  background: #e8f6e8;
  border: 1px solid #009900;
  bottom: 75px;
  padding: 8px 40px;
  position: absolute;
  /*width: 80px;*/
}
.message_close {
  display: block;
  height: 15px;
  overflow: hidden;
  position: absolute;
  right: 2px;
  text-indent: -1000px;
  top: 2px;
  width: 15px;
  background: url(images/iconos/message_close.png) no-repeat 0 0;
}

/** ----- ESTRUCTURA ------ */

body {
  font-family: font('primary');
  font-weight: 300;
  @include respond-to('xs'){
    font-weight: 400;
  }
  font-size: 16px;
  line-height: 1.5;
  /* background: url(images/bg/cepas-y-olivo.jpg) no-repeat center center fixed;
	-webkit-background-size: cover;
			-ms-background-size: cover; 
		 -moz-background-size: cover;
			 -o-background-size: cover;
					background-size: cover;*/
}

img.bg {
  /*display: none;*/
  /* Set rules to fill background */
  min-height: 100%;
  min-width: 1546px;

  /* Set up proportionate scaling */
  width: 100%;
  height: auto;

  /* Set up positioning */
  position: fixed;
  bottom: 0;
  left: 0;

  z-index: -1;
}

@media screen and (max-width: 1546px) {
  /* Specific to this particular image */
  img.bg {
    left: 50%;
    margin-left: -773px; /* 50% */
  }
}

/** ESTRUCTURA GENERAL: contenedores */

.container {
  max-width: 980px;
  margin: 0 auto;
}

.wrapper,
.footer {
  max-width: 980px;
  margin: 0 auto;
}

.wrapper {
  padding-bottom: 10px;
  padding-top: 30px;
  @include respond-to("xs") {
    padding-left: 20px;
    padding-right: 20px;
  }

}

.sidebar {
  width: 245px;
  position: relative;
  float: left;
  padding: 40px 0 40px 28px;
}

/** SIDEBAR / LATERAL */


.sidebar_ct {
  background-color: #fff;
  border-top: 1px solid #dedede;
  margin-top: 22px;
  padding: 25px 0 5px;
}

#navmain,
#navbottom {
  text-align: center;
  margin: 0 15px;
  padding-bottom: 25px;
  font-size: 12px;
  line-height: 26px;
  text-transform: uppercase;
}

#navmain a,
#navbottom a {
  color: #000;
  display: block;
}

#navmain li.current a,
#navmain a:hover {
  background-color: #ebebeb;
}

.sidebar_utilities {
  border-top: 1px solid #dedede;
  background-color: #fff;
  padding-bottom: 30px;
}

#useraccess {
  border-bottom: 1px solid #dedede;
  padding: 10px;
  background-color: #eee;
  position: relative;
  text-align: center;
  text-transform: uppercase;
  font-size: 11px;
  line-height: 13px;
  text-transform: uppercase;
  overflow: hidden;
}

#useraccess ul {
  margin-left: 25px;
  padding: 5px 0;
}

#useraccess li {
  float: left;
  padding: 0 10px;
  border-right: 1px solid #666;
}

#useraccess li.last {
  border: 0;
}

#usernav {
  margin-top: 7px;
  margin-right: 4px;
}

/* Última compra */

#last_order {
  padding: 35px 10px 15px 10px;
}

#last_order h3 {
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  padding-top: 7px;
  border-bottom: 1px solid #dedede;
  border-top: 1px solid #dedede;
  text-transform: uppercase;
  background-color: #eee;
}

#last_order_ct {
  margin-top: 10px;
}

.last_order_product,
.last_order_total {
  clear: both;
  overflow: hidden;
}

.last_order_img {
  float: left;
  margin-right: 10px;
  padding-bottom: 10px;
}
.last_order_content {
  float: left;
  width: 60%;
}

.last_order_total {
  padding: 3px 0;
  text-align: center;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
}

.last_order_quantity {
  text-transform: uppercase;
  font-size: 12px;
}
.last_order_subtotal {
  font-weight: bold;
}

.last_order_actions {
  margin-top: 7px;
}

.last_order_actions a {
  display: block;
  width: 40%;
  font-size: 11px;
  line-height: 11px;
  text-transform: uppercase;
  background-color: #eee;
  padding: 4px 10px;
  text-align: center;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
}

.last_order_actions a.btn_buy {
  background-color: #a00044;
  color: #fff;
  float: left;
}

.last_order_actions a.btn_detail {
  background-color: #dedede;
  color: #000;
  float: right;
}

/** CONTENIDO */

.content_ct {
  body:not(.inicio):not(.products) & {
    background-color: #fff;
    padding: 30px;
  }

}

.inicio .content_ct {
}

/** Paginas fijas */

.content {

  @include respond-to("xs") {
    margin-left: 0;
    margin-right: 0;
  }
}
.inicio .content {
  margin: 0;
}

.content p {
  margin: 8px 0;
}

.text ul,
.text ol {
  margin: 10px 30px 20px 30px;
}

/** Productos */

/* Listado de productos + ficha  */

.elm_over {
  display: none;
}

.cat_list {
  overflow: hidden;
  @include flex-columns(3, 40px);
  .product {
    overflow: hidden;
    position: relative;
    border-bottom: 1px solid #dedede;
    border-right: 1px solid #dedede;
  }
}

.cat_list .product_img {
  position: absolute;
  top: 0;
  bottom: auto;
  left: 0;
  -webkit-transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.cat_list .product_over .product_img {
  top: auto;
  -webkit-transform: translate(0px, -155px);
  -webkit-backface-visibility: hidden;
  -moz-transform: translate(0, -155px);
  -o-transform: translate(0px, -155px);
  -ms-transform: translate(0px, -155px);
  transform: translate(0px, -155px);
}

.cat_list .product_content {
  bottom: 0;
  width: 290px;
  padding: 15px;
  min-height: 65px;
  background: url(images/dot-grey.png);
  cursor: pointer;
}

.cat_list .product_over .product_content {
  background: url(images/dot-black.png);
}

.product_title {
}

.cat_list .product_title h2 {
  padding: 0;
}

.cat_list .product_title h2,
.cat_list .product_title h3 {
  float: left;
  width: 215px;
}

.cat_list .product_over .product_title {
  color: #fff;
}

.cat_list .price_text {
  float: right;
  font-size: 20px;
  line-height: 26px;
  font-weight: 700;
}

.product_buy {
  margin-top: 8px;
}

/* Ficha de producto */

#product_ficha {
  position: relative;
}

.product_text {
  width: 320px;
  color: #333;
}

#product_ficha .product_title {
  background: #eee;
  padding: 12px 20px 5px 20px;
}

#product_ficha .product_title h2 {
  padding: 0;
}

.summary {
  margin: 0 0 20px 20px;
}

.summary h3 {
  font-size: 18px;
  padding-bottom: 5px;
  padding-top: 20px;
}

#product_ficha .product_img {
  width: 265px;
  text-align: center;
  margin-top: 85px;
  position: relative;
}

#product_ficha .product_img img {
  max-width: 100%;
}

/* En oferta */

span.offer_ribbon {
  background: url(images/offer_ribon.png) no-repeat bottom right;
  width: 107px;
  height: 107px;
  top: -2px;
  right: -2px;
  position: absolute;
  display: block;
  text-indent: -20000px;
  z-index: 25px;
}

.cat_list .product_img span {
  top: 0;
  right: 0;
}
/* El cajetín de compra */

.buy_form {
  border: 1px solid #dedede;
  background: #eee;
  position: relative;
  padding: 15px 10px 8px 10px;
}

#product_ficha div.price_text {
}

#product_ficha div.price_text span.normal_price {
  font-size: 30px;
  line-height: 30px;
  padding-left: 13px;
  background-position: 0 8px;
}

#product_ficha .product_cant {
  overflow: hidden;
}

.product_cant .cant_box {
  float: left;
}

#cant_change {
  float: left;
}

.cant_box .text {
  float: left;
  clear: none;
  margin-left: 3px;
  margin-top: 2px;
}

.cant_box .text label {
  padding-left: 8px;
  padding-top: 5px;
  float: right;
}

.cant_box .text input {
  width: 25px;
  text-align: center;
  padding: 5px 2px;
}

.product_add .submit {
  float: right;
  margin-top: 15px;
}

.files {
  margin-top: 5px;
}

.files li {
  list-style-type: none;
  padding-left: 30px;
  padding-top: 5px;
  border-top: 1px solid #dedede;
  background-repeat: no-repeat;
  background-position: 0 5px;
}

.files li.last {
  border-bottom: 1px solid #dedede;
}

.files li.pdf {
  background-image: url(images/files/pdf.png);
}
.files li.doc {
  background-image: url(images/files/doc.png);
}
.files li.xls {
  background-image: url(images/files/xls.png);
}
.files li.ppt {
  background-image: url(images/files/ppt.png);
}

.files li a {
  font-weight: bold;
}

#navproducts {
  position: absolute;
  top: 0;
  right: 0;
}

#navproducts a {
  display: block;
  width: 34px;
  height: 76px;
  overflow: hidden;
  text-indent: -5000px;
  float: left;
  background-image: url(images/cart_icons/navproduct.png);
  background-repeat: no-repeat;
}

#navproducts a.prev_product {
  background-position: 0 0;
}
#navproducts a.prev_product:hover {
  background-position: 0 -77px;
}
#navproducts a.next_product {
  background-position: -34px 0;
  width: 35px;
}
#navproducts a.next_product:hover {
  background-position: -34px -77px;
}

/** FORMULARIOS */

input,
textarea,
select {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 5px 15px;
}

.checkbox input {
  border: 0;
  background: none;
  padding: 0;
  float: left;
  margin-right: 5px;
}

input:focus,
textarea:focus,
select:focus {
  border-color: #999;
  background-color: #fff;
  -moz-box-shadow: 1px 1px 5px #999;
  -webkit-box-shadow: 1px 1px 5px #999;
  -ms-box-shadow: 1px 1px 5px #999;
  -o-box-shadow: 1px 1px 5px #999;
  box-shadow: 1px 1px 5px #999;
}

label {
  display: block;
  padding-right: 5px;
  font-weight: 700;
}
.checkbox label,
.radio label {
  display: inline;
}

.input,
.textarea,
.select {
  margin: 10px 0;
}

.error-message,
label.error {
  background-color: #e7d2db;
  border: 1px solid #903;
  padding: 5px 10px;
  font-weight: bold;
  font-size: 12px;
  color: #42001c;
  margin: 5px 0;
}

/* Usuarios */
.loginbox {
  @include min-to("xs") {
    border: 1px solid #dedede;
    padding: 25px;
  }
  max-width: 600px;
  margin: 0 auto;
  -moz-border-radius: 15px;
  -webkit-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
}

.loginbox_form {
  margin: 0 auto;
}

.captcha_image a {
  display: block;
  color: #000;
  font-weight: bold;
}

/** NAVEGACION INFERIOR */

#navbottom {
  margin: 20px 0 0 10px;
}

#navbottom li {
  float: left;
}

#navbottom a {
  padding: 0 10px;
}

/** FOOTER / PIE */

.footer {
  position: relative;
}

/* Compartir en redes */

#social-share {
  width: 330px;
  margin: 0 auto;
  padding-top: 15px;
}

.share_btn {
  float: left;
}

/* By iLUNE */

#by_ilune {
  position: absolute;
  float: right;
  right: 0;
  top: 4px;
}

#by_ilune a {
  display: block;
  margin-right: 10px;
  margin-top: 5px;
  float: right;
  width: 26px;
  height: 26px;
  overflow: hidden;
  text-indent: -1000px;
  background-repeat: no-repeat;
  background-position: 0 0;
  background-image: url(../../../css/images/by_ilune/ilune_green.png); /* verde */
  /*background-image: url(../../../css/images/by_ilune/ilune_white.png); /* blanco */
  /*background-image: url(../../../css/images/by_ilune/ilune_black.png); /* negro */
}
