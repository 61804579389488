
/* -------------------------------------------------------------------------
 * shadowS
 *
 * Define shadows foundation
 *
 * Values should be expressed in PX.
 * If you need a relative unit based on that values, use the core functions
 * on used context.
 *
 * Colors are not included
 *
 */

// Main shadow

$v-shadow:                    2px 2px 2px 2px !default;

// shadow variants

$v-shadow--discrete:          $v-shadow !default;
$v-shadow--elevated:          0 14px 28px 0 !default;
$v-shadow--sunken:            0 0 28px 0 !default;


// shadowS MAP

$v-shadows: (
  "discrete":                 $v-shadow--discrete,
  "elevated":                 $v-shadow--elevated,
  "sunken":                   $v-shadow--sunken,
  "none":                     0px 0px 0px 0px
) !default;

@function f-shadow( $_name: discrete){
  @return map-get( $v-shadows, $_name);
}