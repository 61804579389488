
$v-grid__columns: 12 !default;
$v-layout-max-width: m(1560px) !default;

$v-colors: (
  // Global
  "primary": #851648,
  "secondary": #f7d349,
  "black": #000,
  "white": #fff,
  "grey": #696969,
  "background": #f6f6f6,
  "border": #cecece,

  // States
  "state-success": #36ba33,
  "state-info": #20a0de,
  "state-warning": #f99500,
  "state-danger": #c1101f,
) !default;


$v-fonts: (
  primary: #{'Roboto Condensed', serif},
  secondary: #{'PT Serif', serif},
);

$v-texts: (
  xs: m(12px),
  sm: m(14px),
  normal: m(18px),
  head1: m(24px),
  head2: m(36px),
  head3: m(45px),
  head4: m(50px),
  head5: m(100px),
);

$v-leads: (
  1: 1.08,
  2: 1.30,
  3: 1.30,
  '3-2': 1.70,
  4: 1.89
);

$v-layouts: (
  ss: 320px,
  xs: 520px,
  sm: 778px,
  mobile: 778px,
  md: 960px,
  lg: 1024px
) !default;

$gutter: (
  default: m(55px),
  lg: m(40px),
  md: m(40px),
  sm: m(20px),
  xs: 20px,
);


$v-spaces: (
  0: 0px,
  1: 10px,
  2: 26px,
  3: 40px,
  4: 56px,
  5: 80px,
  6: 95px,
  7: 105px
) !default;

$v-weights: (
  hairline: 100,
  thin: 200,
  light: 300,
  normal: 400,
  medium: 500,
  semibold: 600,
  bold: 700,
  extrabold: 800,
  black: 900
) !default;

$v-header: 160px;


