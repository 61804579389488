
/* -------------------------------------------------------------------------
 * STRIP UNITS
 *
 * Returns the unitless number of a given value.
 * Useful for calculations and unit conversion.
 *
 * s-core-strip-units(16px) == 16
 */

@function s-core-strip-units($_value) {

  @if (type-of($_value) != number) {
    @error "s-core-strip-units: `#{$_value}` needs to be a number.";
  }

  @if not unitless($_value) {
    @return $_value / ($_value * 0 + 1);
  }

  @return $_value;
}
