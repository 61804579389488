.c-header-wrapper {
    top: 0;
    width: 100vw;
    left: 0;
    z-index: 9;
    @include respond-to("xs") {
        background-color: #fff;
    }
}

.c-header {
    @include min-to('xs'){
        @include overpass();
    }
    background-color: color("black");
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding-left: 20px;
    padding-right: 20px;

    @include respond-to('xs'){
      height: 80px;
      justify-content: space-between;
      background-color: #fff;
    }
}

.c-navmain {
    display: flex;

    @include respond-to("xs") {
        position: fixed;
        flex-direction: column;
        width: 100vw;
        top: 80px;
        left: 0;
        padding: 30px 0px;
        background-color: color("black");
        z-index: 10;
        transition: transform 0.5s;
        transform: translateX(calc(-100vw - 80px));
        &.active {
            transform: translateX(0);
        }
    }
}

.c-navmain,
.c-usernav {
    ul {
        display: flex;
        justify-content: center;

        @include respond-to("xs") {
            flex-direction: column;
            align-items: center;
        }

        li {
            margin: 0px 10px;

            a {
                text-transform: uppercase;
                color: #fff;
                font-weight: bold;
                font-size: 17px;
                display: block;
                padding: 10px 0px;

            }

            &.current, &:hover {
                a {
                    color: color('primary');
                }
            }
        }
    }
}
.c-usernav {
    display: flex;
    align-items: center;

    @include respond-to('xs'){
      flex-direction: column;

    }
}

.c-burger {
    font-size: 40px;
    line-height: 0;
    color: color('primary');
}

.c-navmain-close {
    display: block;

    &.active {
        display: none;
    }

    position: fixed;
    top: 20px;
    right: 20px;
    color: #fff;
    z-index: 11;
    font-size: 30px;
}

.c-logo-sm {
    margin-left: 30px;
    flex-grow: 1;
    img {
        width: auto;
        height: 60px;
    }
    a {
        display: block;
    }
}

.c-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 40px 0px;
    .sticking & {
        padding: 10px 0px;
    }
    background: #fff;
    text-align: center;
    a {
        display: inline-block;
    }

    img {
        transition: width .5s;
        width: 140px;

        .sticking & {
            width: 50px;
        }
    }
}

.c-usernav-sm  {
    flex: 0 0 100px;
    display: flex;
    justify-content: flex-end;

    button, a {
        display: block;
        margin-left: 14px;
        font-size: 30px;
        color: color('black');
        padding: 0;
    }

    button {
        font-size: 25px;
    }
}

.cart-button {
    cursor: pointer;
    background: none;
    border: none;

    color: #fff;
    @include respond-to('xs'){
      color: color('black');
    }
}